// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-benefit-js": () => import("./../../../src/templates/benefit.js" /* webpackChunkName: "component---src-templates-benefit-js" */),
  "component---src-templates-benefits-js": () => import("./../../../src/templates/benefits.js" /* webpackChunkName: "component---src-templates-benefits-js" */),
  "component---src-templates-benefits-provider-js": () => import("./../../../src/templates/benefits-provider.js" /* webpackChunkName: "component---src-templates-benefits-provider-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-posts-category-js": () => import("./../../../src/templates/posts-category.js" /* webpackChunkName: "component---src-templates-posts-category-js" */),
  "component---src-templates-posts-js": () => import("./../../../src/templates/posts.js" /* webpackChunkName: "component---src-templates-posts-js" */),
  "component---src-templates-search-js": () => import("./../../../src/templates/search.js" /* webpackChunkName: "component---src-templates-search-js" */)
}

