import 'bootstrap/dist/js/bootstrap.min.js';
import '@popperjs/core/dist/umd/popper.min.js';
import React from 'react';

// Wraps every page in a component
export const wrapPageElement = ({ element }) => {
  return (
    <>
      <div id="dp8PuFBeLpdIr7WZqLezNXoqdwgk5-5zgPnpU1NJbgv4"></div>
      <script src="https://mktdplp102cdn.azureedge.net/public/latest/js/ws-tracking.js?v=1.84.2007"></script>
      <div
        className="d365-mkt-config"
        style={{ display: 'none' }}
        data-website-id="p8PuFBeLpdIr7WZqLezNXoqdwgk5-5zgPnpU1NJbgv4"
        data-hostname="31b96163b0814eabb08d5043b45a94d7.svc.dynamics.com"
      ></div>
      {element}
    </>
  );
};
